import { graphql, readInlineData } from "react-relay";

import { section } from "shared/data";

import {
  checkCanShowInPlusPlatform,
  checkIsPlusArticle,
} from "scmp-app/components/article/article-render/plus/helpers";
import { checkIsNewsAgendaTopic } from "scmp-app/components/plus/news-agenda/helpers";
import { pathname as SectionPathname } from "scmp-app/pages/section/index";
import type { helpersGetTypeNameNode$key } from "scmp-app/queries/__generated__/helpersGetTypeNameNode.graphql";

// This is to avoid missing query parameters from a base link for the app bar when redirecting with client route push, e.g. appBarVariant."
export const shouldRedirectBaseDynamicSectionLink = (
  pathName: string,
  query?: Record<string, string | undefined>,
) => {
  if (query?.appBarVariant) return false;
  return pathName === SectionPathname;
};

type GetIsSubSectionParameters = {
  entityId: string;
  parentSection: Nullish<string>;
};

export const getIsSubSection = ({ entityId, parentSection }: GetIsSubSectionParameters) => {
  const entityIdsOfSubSectionWithoutParentSection: string[] = [
    section.announcements.entityId,
    section.recap.entityId,
  ];
  // To allow section with parent section while all assigned to section instead of sub section
  const parentSectionUuidsOfSection: string[] = [
    section.magazine.entityUuid,
    section.news.entityUuid,
    section.posties.read.entityUuid,
  ];
  // To allow specific section with parent section assigned to section instead of sub section while others under the parent section remained unchanged
  const entityIdsOfSpecificSectionWithParentSection: string[] = [
    section.martialArts.entityId,
    section.olympic.entityId,
    section.us.usElections.entityId,
  ];
  if (entityIdsOfSpecificSectionWithParentSection.includes(entityId)) return false;

  return (
    entityIdsOfSubSectionWithoutParentSection.includes(entityId) ||
    (parentSection && !parentSectionUuidsOfSection.includes(parentSection))
  );
};

type GetTypeNameParameters = {
  query: Record<string, unknown>;
  reference: helpersGetTypeNameNode$key;
};

export const getTypeName = ({ query, reference }: GetTypeNameParameters) => {
  const content = readInlineData(
    graphql`
      fragment helpersGetTypeNameNode on BaseWithApplicationAndUrlAlias @inline {
        __typename
        entityId
        ... on Section {
          parentSection
        }
        ...helpersCheckIsNewsAgendaTopicTopic
        ...helpersCheckCanShowInPlusPlatformArticle
        ...helpersCheckIsPlusArticle
      }
    `,
    reference,
  );

  const { __typename, entityId, parentSection } = content;

  const isSubSection = getIsSubSection({
    entityId,
    parentSection,
  });

  if (checkIsNewsAgendaTopic(content)) {
    return "NewsAgenda";
  } else if (__typename === "Section" && isSubSection) {
    return "SubSection";
  } else if (
    __typename === "Article" &&
    (checkIsPlusArticle(content) ||
      (checkCanShowInPlusPlatform(content) && query.display === "plus"))
  )
    return "Plus";
  return __typename;
};
